<template>
  <div id="Safety-Setting">
    <div id="setting-password">
      <div class="top title components">修改密码</div>
      <div class="bottom content">
        <template
          v-for="(item, key) in menuList.filter(
            (item) =>
              item.meta.classify && item.meta.classify.includes('password')
          )"
        >
          <router-link
            :key="key"
            :to="{ name: item.name }"
            :class="`setting-${item.name}`"
          >
            <div class="left">{{ item.meta.title }}</div>
            <div class="right"><a-icon type="right" /></div>
          </router-link>
        </template>
      </div>
    </div>
    <div id="setting-info">
      <div class="top title components">修改信息</div>
      <div class="bottom content">
        <template
          v-for="(item, key) in menuList.filter(
            (item) => item.meta.classify && item.meta.classify.includes('info')
          )"
        >
          <router-link
            :key="key"
            :to="{ name: item.name }"
            :class="`setting-${item.name}`"
          >
            <div class="left">{{ item.meta.title }}</div>
            <div class="right"><a-icon type="right" /></div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: {},
    };
  },
  methods: {
    getChildrenComponents() {
      const route = this.$route.matched;
      const router = this.$router.options.routes;
      this.menuList = router
        .filter((item) => item.name === route[0].name)[0]
        .children.filter(
          (item) => item.meta.classify && item.meta.classify.includes("setting")
        );
    },
  },
  created() {
    this.getChildrenComponents();
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Safety-Setting {
  > div {
    > .bottom.content {
      padding: 0.1em 1em;
      border-radius: 0.5em;
      background-color: rgba(61, 68, 123, 1);
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);

      > a {
        height: 3em;
        line-height: 3em;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
      }

      > a + a {
        border-top: 1px solid rgba(86, 94, 158, 1);
      }
    }
  }
}
</style>
